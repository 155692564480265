var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',_vm._b({attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedPoints,"item-class":"text-body-1","caption":_vm.caption,"use-item-slot":true,"hide-default-footer":false,"use-caption-as-index":false},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('v-spacer'),_c('v-text-field',{staticClass:"data-table__search",attrs:{"placeholder":"Поиск","prepend-inner-icon":"mdi-magnify","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item",fn:function(ref){
var ref_item = ref.item;
var index = ref_item.index;
var item = ref_item.item;
return [_c('tr',{staticClass:"data-table__text data-table__text_cursor_default text--lighten-1",class:!item.state && 'not-active-counter',staticStyle:{"cursor":"pointer"},on:{"click":function () {
            var pu = _vm.points.pointList.find(function (point) { return point['счетчик'] === item.counter; })
            _vm.chooseDeviceForHistory(pu, function () { return _vm.$emit('update:slider', true); });
          }}},_vm._l((item),function(value,header){return _c('td',{key:("" + header),style:(header.endsWith('counter') && 'display: none')},[[_vm._v(" "+_vm._s(value || "—")+" ")]],2)}),0)]}}])},'x-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }