

















































import { Component, Mixins, Prop } from "vue-property-decorator";
import XDataTable from "@/components/hoc/Table.vue";
import { mapGetters } from "vuex";
import { AccountingPointsByObject } from "@/models/accounting-point";
import TableDropdown from "@/components/for-contract-card/TableDropdown.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: { XDataTable, TableDropdown },
  computed: {
    ...mapGetters({ contractType: "contract/type" }),
    ...mapGetters({ accountingPoints: "accountingPoints/allPoints" })
  },
})
class HistoryTabContent extends Mixins(AppApiMixin) {

  [x: string]: any;
  page = 1;
  pageCount = 0;
  itemsPerPage = 10;
  log(data: any) {
    console.log(data)
  }

  @Prop({ required: true }) readonly points!: AccountingPointsByObject;

  headers = [
    { text: "Номер", value: "number", /*width: "5%",*/ electricity: true },
    { text: "Наименование", value: "name", /*width: "17.5%",*/ electricity: true },
    { text: "Адрес", value: "address", /*width: "17.5%",*/ electricity: true },
    { text: "Марка", value: "type", /*width: "10%"*/ },
    { text: "Услуга", value: "usl", /*width: "15%"*/ },
    { text: "Заводской номер", value: "id", /*width: "15%"*/ },
    { text: "Тип", value: "variant", /*width: "15%"*/ },
    { text: "Состояние", value: "state", /*width: "10%"*/ },
    { text: "_", value: "counter", align: ' d-none' },
  ];

  pointKeys = [
    {name: "номерту", electricity: true},
    {name: "названиету", electricity: true},
    {name: "адрес", electricity: true},
    {name: "типсчетчика", electricity: false},
    {name: "услуга", electricity: false},
    {name: "заводскойномер", electricity: false},
    {name: "вариантстрока", electricity: false},
    {name: "даткнц", electricity: false},
    {name: "счетчик", electricity: false},
  ];

  search = "";

  public get caption() {
    const name = this.points.objectName;
    const address = (this.points.pointList[0].адрес).trim();
    if (!!name) {
      if(name.indexOf('ТУ') === 0) {
        return name.replace('ТУ', `ТУ № ${this.points.objectId}: ${address},`)
      } else {
        return name + ` № ${this.points.objectId}: ${address},`
      }
    } else {
      return 'Без привязки'
    }
  }

  public get totalUsedPointKeys(): number {
    return this.contractType === 3 ? this.pointKeys.length : 5;
  }

  public get preparedHeaders() {
    return  this.contractType === 3 ? this.headers : this.headers.filter((header: {[x:string]: any}) => !header.electricity);
  }

  public get preparedPoints(): Array<string[]> {
    const points = this.points.pointList;
    const pointKeys = this.contractType === 3 ? this.pointKeys : this.pointKeys.filter((key: {[x:string]: any}) => !key.electricity);

    return  points.map((point) => pointKeys.map((key) => {
      if (key.name === 'даткнц') {
        return Date.parse(point[key.name]) > Date.now() ? 'Работает' : ''
      }
      return point[key.name]
    }));
  }
}

export default HistoryTabContent;
