






























import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import ValuesInput from "./ValuesInput.vue";
import ValuesHistory from "./ValuesHistory.vue";
import XButton from "@/components/SimpleButton.vue";
import XSlider from "@/components/SimpleSlider.vue";
import MassValuesInput from "./MassValuesInput.vue";

@Component({
  components: {
    Page,
    XTabs,
    ValuesInput,
    ValuesHistory,
    XButton,
    XSlider,
    MassValuesInput,
  },
  computed: {
    ...mapState({ appEnvironment: "appEnvironment" }),
  },
})
class CountersValues extends Vue {
  [x: string]: any;

  titles = ["Ввод показаний", "История показаний"];
  components = [ValuesInput, ValuesHistory];

  showMassValuesForm = false;

  public get useValuesInput(): boolean {
    const { appEnvironment } = this;

    return !!(appEnvironment.constants && appEnvironment.constants["ЛКЮЛПОКАЗ"]);
  }
}

export default CountersValues;
